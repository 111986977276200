import React, { useEffect, useState } from 'react'
import BN from 'bignumber.js'
import { kit, getContract } from '../../root'
import { formatAmount, print, ether } from '../../utils'

const useCheckRequiredBalance = (value, txType = 'CELO', initValue) => {
  const [isLoading, setLoading] = useState(true)
  const [requiredBalance, setBalance] = useState(initValue || { isNotEnoughBalance: true, minimumRequiredBalance: 0 })

  const loadBalance = async () => {
    const { priceOracle, tokens } = await getContract()
    const token = tokens[txType]
    const celoPerToken = await priceOracle.methods.getAssetPrice(token.address).call()

    const isCELO = txType === 'CELO'

    const gasPriceCelo = await kit.web3.eth.getGasPrice()
    
    const gasPrice = isCELO ? gasPriceCelo : BN(gasPriceCelo).multipliedBy(ether).dividedBy(celoPerToken).multipliedBy('1.1').toFixed(0)

    // estimated gas is 5mln just because we dont know how much valora will send in transaction object
    const gasFee = BN(gasPrice).multipliedBy('5000000')
    const minimumRequiredBalance = gasFee.plus(value || 0)

    const stableToken = isCELO ? await kit.contracts.getGoldToken() : await kit.contracts.getStableToken(txType)
    const tokenBalanceBig = await stableToken.balanceOf(kit.defaultAccount)

    const isEnoughBalance = tokenBalanceBig.isGreaterThanOrEqualTo(minimumRequiredBalance)
    const balanceDiff = !isEnoughBalance ? minimumRequiredBalance.minus(tokenBalanceBig): BN(0)

    setBalance({
      minimumRequiredBalance: formatAmount(minimumRequiredBalance.dividedBy(BN(10).pow(18)), txType),
      balanceDiff: formatAmount(balanceDiff.dividedBy(BN(10).pow(18)), txType),
      gasFee: gasFee.dividedBy(BN(10).pow(18)),
      isNotEnoughBalance: !isEnoughBalance
    })
    setLoading(false)
  }

  useEffect(() => {
    loadBalance()
  }, [txType])

  return [requiredBalance, isLoading]
}

export default useCheckRequiredBalance