import React, { useContext } from "react"
import { fade, makeStyles, withStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import { ListItemText } from "@material-ui/core"
import { Text } from 'react-native'
import AppContext from '../AppContext'

const styles = theme => ({
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  }
});

const AppToolbar = ({ classes, onClickMenu }) => {
  const { user } = useContext(AppContext)
  return (
    <div className={classes.grow}>
      <AppBar position="static" color="primary">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={onClickMenu}
          >
            <MenuIcon />
          </IconButton>
          <ListItemText primary="Moola App" />
          { user && (
            <Text style={{ fontSize: 16 }}>
              {`Wallet : ${user.address.slice(0, 4)}...${user.address.slice(user.address.length - 3)}`}
            </Text>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
  
export default withStyles(styles)(AppToolbar);
