import React, { useEffect, useContext, useState } from 'react'
import BN from 'bignumber.js'
import AppContext from '../AppContext'
import { print, printRay } from '../../utils'
import { getContract } from '../../root'

// Utils
const INTEREST_RATE = {
  1: 'Stable',
  2: 'Variable',
  0: 'n/a',
};

// Hook
const useAccountReserveData = (txType = 'CELO') => {
  const context = useContext(AppContext)
  const [isLoading, setLoading] = useState(true)
  const [accountReserveData, setAccountReserveData] = useState(null)

  const loadData = async () => {
    const { lendingPool, reserves } = await getContract()
    const data = await lendingPool.methods.getUserReserveData(reserves[txType], context.user.address).call()
    const parsedData = {
      deposited: print(data.currentATokenBalance),
      borrowed: print(data.principalBorrowBalance),
      debt: print(data.currentBorrowBalance),
      rateMode: INTEREST_RATE[data.borrowRateMode],
      borrowRate: BN(printRay(data.borrowRate)).multipliedBy(100).toFixed(2, 2),
      liquidityRate: BN(printRay(data.liquidityRate)).multipliedBy(100).toFixed(2, 3),
      originationFee: print(data.originationFee),
      borrowIndex: printRay(data.variableBorrowIndex),
      lastUpdate: (new Date(BN(data.lastUpdateTimestamp).multipliedBy(1000).toNumber())).toLocaleString(),
      isCollateral: data.usageAsCollateralEnabled,
    }
    setAccountReserveData(parsedData)
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  return [{ accountReserveData, isLoading }]
}

export default useAccountReserveData