import React from 'react'
import withView from '../../components/HOCs/withView'
import requiresAuth from '../../components/HOCs/auth'
import { useHistory } from 'react-router-dom'
import { Text } from 'react-native'
import Button from '@material-ui/core/Button'
import { Container, DepositType } from './elements'

const RepayScreen = () => {
  const history = useHistory()

  const processInCelo = () => {
    history.push('/repay/CELO')
  }

  const processInCUSD = () => {
    history.push('/repay/cUSD')
  }

  const processInCEUR = () => {
    history.push('/repay/cEUR')
  }

  return (
    <>
      <Container>
        <Text>Which asset would you like to repay?</Text>
        <DepositType>  
          <Button color="secondary" variant="contained" onClick={processInCUSD}>Repay cUSD</Button>
        </DepositType>
        <DepositType>  
          <Button color="secondary" variant="contained" onClick={processInCEUR}>Repay cEUR</Button>
        </DepositType>
        <DepositType>  
          <Button color="secondary" variant="contained" onClick={processInCelo}>Repay CELO</Button>
        </DepositType>  
      </Container>
    </>
  )
}

export default requiresAuth(withView(RepayScreen))
