import React, { useContext, useState } from 'react';
import withView from '../../components/HOCs/withView'
import requiresAuth from '../../components/HOCs/auth'
import useBalance from '../../components/hooks/useBalance'
import useAccountReserveData from '../../components/hooks/useAccountReserveData'
import useCheckRequiredBalance from '../../components/hooks/useCheckRequiredBalance'
import useTxRequest from '../../components/hooks/useTxRequest'
import { useParams } from 'react-router-dom'
import { Text } from 'react-native'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import ValueSlider from '../../components/ValueSlider'
import MuiAlert from '@material-ui/lab/Alert'
import { Form, Field } from 'react-final-form'
import { FeeCurrency, cancelRequest } from '../../sdk/dappkit'
import { web3, kit, getContract } from '../../root'
import { formatAmount, getValuePlaceholder } from '../../utils'
import { required, mustBeNumber, mustBePositiveNumber, composeValidators } from '../../utils/validation'
import { FormContainer } from './elements'
import BN from 'bignumber.js'
import AppContext from '../../components/AppContext'

// Utils
const maxValue = max => (value) => {
  const message = BN(max).gt(0)
    ? BN(value).gt(max) && `Should be less than or equal to ${max}`
    : 'Not enough balance'
  
  return message;
}

// Component
const DepositFormScreen = () => {  
  const context = useContext(AppContext)
  const { user: { walletType } } = context
  const { txCurrency } = useParams()
  const operation = 'deposit'
  const requestId = operation + txCurrency

  const isCelo = txCurrency === 'CELO'
  const isStableToken = !isCelo

  const [amount, setAmount] = useState('')

  const [{ balance, isLoading: isBalanceLoading }] = useBalance(txCurrency)
  const [{ accountReserveData, isLoading: isAccountReserveLoading }] = useAccountReserveData(txCurrency)
  const [{ isNotEnoughBalance, balanceDiff, gasFee }, isRequiredBalanceLoading] = useCheckRequiredBalance(amount, txCurrency)
  const { handleTxRequest } = useTxRequest()

  const processOperation = async ({ amount }) => {
    cancelRequest(requestId)

    setAmount(amount)
    const { lendingPool, lendingPoolCore, reserves, tokens } = await getContract()
    const value = BN(10).pow(18).multipliedBy(amount).toFixed(0)

    try {
      const txObject = lendingPool.methods.deposit(reserves[txCurrency], value, 0)

      const txParamCelo = {
        tx: txObject,
        from: context.user.address,
        to: lendingPool.options.address,
        // hello valora. Fix for rounding issues in mobile app
        // https://github.com/celo-org/celo-monorepo/issues/6830
        value: isCelo ? BN(value).plus('1000000000').toFixed(0) : 0,
        estimatedGas: 2000000,
        feeCurrency: isCelo ? undefined : FeeCurrency[txCurrency]
      }

      let txParams = []
      
      if (isStableToken) {
        const stableToken = tokens[txCurrency]
        const approveTxObj = await stableToken.approve(lendingPoolCore.options.address, value).txo
        const txParamStableToken = {
          tx: approveTxObj,
          from: context.user.address,
          to: stableToken.contract.options.address,
          estimatedGas: 1000000,
          feeCurrency: FeeCurrency[txCurrency]
        }
        txParams = [txParamStableToken, txParamCelo]
      } else {
        txParams = [txParamCelo]
      }

      handleTxRequest(operation, requestId, txCurrency, amount, txParams)
    } catch (error) {
      console.log(error)
      context.showError(error.message)
    }
  }

  const maxAmount = !isRequiredBalanceLoading
    ? BN.maximum(BN(balance).minus(gasFee).minus(1), 0)
    : BN(balance)

  const maxValueValidator = maxValue(maxAmount)
    
  const validators = balance && composeValidators(
    required, 
    mustBeNumber, 
    mustBePositiveNumber,
    maxValueValidator
  )

  return (
    <Form
      onSubmit={processOperation}
      initialValues={{ }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <FormContainer onSubmit={handleSubmit} $showWarning={isNotEnoughBalance}>
          <Text>{`How much ${txCurrency} would you like to deposit?`}</Text>
          <Field 
            name="amount"
            validate={validators}
            render={({ input, meta }) => (
              <TextField 
                inputProps={{
                  ...input,
                  onChange: (event) => {
                    const { value } = event.target
                    input.onChange(event)
                    const newPercentage = value ? BN(value).dividedBy(maxAmount).multipliedBy(100).toFixed(0) : 0
                    form.change('percentage', newPercentage)
                  },
                  inputMode: 'decimal',
                  type: 'number'
                }} 
                helperText={meta.touched ? meta.error : ''} 
                error={meta.error && meta.touched} 
                placeholder={getValuePlaceholder(txCurrency)}
                label="Deposit value"
                variant="outlined"
              />
            )}
          />
          <Field 
            name="percentage"
            render={({ input }) => (
              <ValueSlider form={form} input={input} maxAmount={maxAmount} isLoading={isBalanceLoading} />
            )}
          />
          <Text>{`Wallet ${txCurrency} balance is: ${isBalanceLoading ? 'loading...' : formatAmount(balance, txCurrency)}`}</Text>
          <Text>{`${txCurrency} Deposit APY: ${isAccountReserveLoading ? 'loading...' : accountReserveData.liquidityRate + '%'}`}</Text>
          <Button color="secondary" variant="contained" onClick={handleSubmit} disabled={isBalanceLoading || pristine || isNotEnoughBalance}>
            {`Deposit ${txCurrency}`}
          </Button>
          {!isRequiredBalanceLoading && isNotEnoughBalance && (
            <MuiAlert variant="filled" severity="warning">
              {`Missing ${balanceDiff} ${txCurrency} to send transaction. Please fund your wallet.`}
            </MuiAlert>
          )}
        </FormContainer>
      )}
    />
  );
}

export default requiresAuth(withView(DepositFormScreen));
