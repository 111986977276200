import React from 'react'
import withView from '../../components/HOCs/withView'
import requiresAuth from '../../components/HOCs/auth'
import { useHistory } from 'react-router-dom'
import useBalance from '../../components/hooks/useBalance'
import { Text } from 'react-native'
import Button from '@material-ui/core/Button'
import { Container, DepositType } from './elements'
import { formatCELO, formatCUSD, formatCEUR } from '../../utils'

const DepositScreen = () => {
  const history = useHistory()
  const [{ balance: cUSDBalance, isLoading: iscUSDLoading}] = useBalance('cUSD')
  const [{ balance: cEURBalance, isLoading: iscEURLoading}] = useBalance('cEUR')
  const [{ balance: celoBalance, isLoading: isCeloLoading}] = useBalance('CELO')

  const processInCelo = () => {
    history.push('/deposit/CELO')
  }

  const processInCUSD = () => {
    history.push('/deposit/cUSD')
  }

  const processInCEUR = () => {
    history.push('/deposit/cEUR')
  }

  return (
    <>
      <Container>
        <Text>Which asset would you like to deposit?</Text>
        <DepositType>  
          <Text>{`cUSD Balance: ${iscUSDLoading ? 'loading...' : formatCUSD(cUSDBalance) }`}</Text>
          <Button color="secondary" variant="contained" onClick={processInCUSD}>Deposit cUSD</Button>
        </DepositType>
        <DepositType>  
          <Text>{`cEUR Balance: ${iscEURLoading ? 'loading...' : formatCEUR(cEURBalance) }`}</Text>
          <Button color="secondary" variant="contained" onClick={processInCEUR}>Deposit cEUR</Button>
        </DepositType>
        <DepositType>  
          <Text>{`CELO Balance: ${isCeloLoading ? 'loading...' : formatCELO(celoBalance) }`}</Text>
          <Button color="secondary" variant="contained" onClick={processInCelo}>Deposit CELO</Button>
        </DepositType>  
      </Container>
    </>
  )
}

export default requiresAuth(withView(DepositScreen))
