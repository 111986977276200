import React, { useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useContractKit } from '@celo-tools/use-contractkit'
import AppContext from '../../components/AppContext'
import { Linking } from 'expo'
import { requestAccountAddress, waitForAccountAuth } from '../../sdk/dappkit'
import queryString from 'query-string'
import { kit } from '../../root'


const useLogin = () => {
  const context = useContext(AppContext)
  const location = useLocation()
  const history = useHistory()
  const { connect: connectWallet, address: walletAddress } = useContractKit()
  useEffect(() => {
    walletAddress && setAddress(walletAddress, 'CEW') // CeloExtensionWallet
  }, [walletAddress])

  const setAddress = (address, type) => {
    kit.defaultAccount = address
    context.setUser({ address, walletType: type })
    history.replace('/')
  }

  const login = async (walletType) => {
    if (context.user) return 

    if (walletType === 'VALORA') {
      const dappName = "Moola";
      const requestId = 'login'
      const callback = Linking.makeUrl('#' + location.pathname)

      requestAccountAddress({
        requestId,
        dappName,
        callback,
      })

      const { account: address } = await waitForAccountAuth(requestId)
      setAddress(address, walletType)
    } else {
      connectWallet()
    }
  }

  return login
}

export default useLogin
