import React from 'react'
import { StyleSheet, View } from 'react-native';
import styled from 'styled-components';

// Utils
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexFlow: 'column nowrap',
    backgroundColor: '#fff',
    alignItems: 'center',
    width: '100%',
    height: 'calc(100% - 56px)'
  }
});

export const Wrapper = styled.div`
  max-width: 380px;
  @media only screen and (max-width: 320px) {
    max-width: 320px;
  }
`

// Component
const withView = (WrappedComponent) => {
  return React.memo((props) => (
    <View style={styles.container}>
      <Wrapper>
        <WrappedComponent {...props} key={Date().toString()} />
      </Wrapper>
    </View>
  ))
}

export default withView
