import { useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import { useContractKit } from '@celo-tools/use-contractkit'
import { toTxResult } from '@celo/connect'
import { produceTxParams } from '../../sdk/valora'
import { requestTxSig, waitForSignedTxs } from '../../sdk/dappkit'
import { Linking } from 'expo'
import AppContext from '../AppContext'
import { kit as rootKit } from '../../root'

const dappName = "Moola"
const delay = (time = 1000) => async () => await new Promise((resolve) => setTimeout(resolve, time))

const sendTransaction = (kit, history) => async (operation, walletType, txCurrency, txValue, rawTxParams, redirectOnSuccess = false) => {
  const sender = walletType === 'CEW'
    ? kit.connection.sendTransaction
    : kit.connection.sendSignedTransaction

  // Get the transaction result, once it has been included in the Celo blockchain
  let txReceipt = null
  for(let rawParam of rawTxParams) {
    txReceipt = await (await sender(rawParam)).waitReceipt()
  }
  
  const { transactionHash } = txReceipt
  redirectOnSuccess && history.replace(`/transaction-confirmed/${operation}/${txCurrency}/${txValue}/${transactionHash}`)
}

const sendValoraTransaction = (kit, context, history) => async (operation, walletType, txCurrency, txValue, rawTxParams) => {
  try {
    context.showLoading()
    await sendTransaction(kit, history)(operation, walletType, txCurrency, txValue, rawTxParams, true)
    context.hideLoading()
  } catch (error) {
    console.log(error)
    context.hideLoading()
    context.showError(error.message)
  }
}

const handleTransactionRequest = (kit, contractKit, context, history, location) => async (operation, requestId, txCurrency, txValue, txParams) => {
  const { user: { walletType } } = context
  const { performActions } = contractKit

  const callbackUrl = location.pathname + `/${txValue}`
  // using hash to redirect to same tab in iOS
  const callback = Linking.makeUrl('#' + callbackUrl)
  
  // for Valora mobile app will be opened using deeplink
  // for CEW we just use prepared transaction params
  const preparedTxParams = await requestTxSig(
    kit,
    txParams,
    { requestId, dappName, callback },
    walletType
  );

  switch(walletType) {
    case 'CEW': {
      const getTxAction = (txParams, redirectOnSuccess) => async (cewKit) => {
        const rawTxParams = await produceTxParams(cewKit, txParams)
        await sendTransaction(cewKit, history)(operation, walletType, txCurrency, txValue, rawTxParams, redirectOnSuccess)
      }

      const actions = preparedTxParams.length == 1 
        ? [delay(800), getTxAction([preparedTxParams[0]], true), delay(1000)]
        : [getTxAction([preparedTxParams[0]]), getTxAction([preparedTxParams[1]], true), delay(1000)]
      
      await performActions(...actions);
      break
    } 
    default: {
      const response = await waitForSignedTxs(requestId)
      if (response) { 
        const rawTxParams = [].concat(response.rawTxs) 
        sendValoraTransaction(kit, context, history)(operation, walletType, txCurrency, txValue, rawTxParams)
      }
    }
  }
}

const useTxRequest = () => {
  const context = useContext(AppContext)
  const history = useHistory()
  const location = useLocation()
  const contractKit = useContractKit()

  const handleTxRequest = handleTransactionRequest(rootKit, contractKit, context, history, location)

  return { handleTxRequest }
}

export default useTxRequest


