import React from 'react'
import withView from '../../components/HOCs/withView'
import useLogin from '../../components/hooks/useLogin'
import { useHistory } from 'react-router-dom'
import { Text } from 'react-native'
import Button from '@material-ui/core/Button'
import { Container, TextBlock } from './elements'

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

const WelcomeScreen = () => {
  const login = useLogin()
  const history = useHistory()

  const explainAboutValora = () => {
    history.push('/about-valora')
  }

  const connectCeloExtensionWallet = () => {
    login('CEW')
  }

  return (
    <Container>
      <TextBlock>
        <Text>Welcome to Moola!</Text>
        <Text>Moola is in public beta and is only accessible on mobile devices and on desktop in Chrome and Brave browsers.</Text>
        <Text>Please click below to connect Moola with your wallet.</Text>
      </TextBlock>
      {isMobile && <Button color="secondary" variant="contained" onClick={() => login('VALORA')}>Connect Valora</Button>}
      {!isMobile && <Button color="secondary" variant="contained" onClick={connectCeloExtensionWallet}>Connect Wallet</Button>}
      {isMobile && <Button color="secondary" variant="contained" onClick={explainAboutValora}>What's Valora?</Button>}
    </Container>
  )
}

export default withView(WelcomeScreen)
