import React from 'react'

const AppContext = React.createContext({ 
  showLoading: null, 
  showError: null, 
  setUser: null,
  user: null
})

export default AppContext
