import React from 'react'
import withView from '../../components/HOCs/withView'
import requiresAuth from '../../components/HOCs/auth'
import useAccountData from '../../components/hooks/useAccountData'
import { useHistory } from 'react-router-dom'
import { Text } from 'react-native'
import Button from '@material-ui/core/Button'
import { Container, DepositType } from './elements'

const BorrowScreen = () => {
  const history = useHistory()
  const [{ accountData }] = useAccountData()

  const processInCelo = () => {
    history.push('/borrow/CELO')
  }

  const processInCUSD = () => {
    history.push('/borrow/cUSD')
  }

  const processInCEUR = () => {
    history.push('/borrow/cEUR')
  }

  return (
    <>
      <Container>
        <Text>Which asset would you like to borrow?</Text>
        <DepositType>  
          <Button color="secondary" variant="contained" onClick={processInCUSD}>Borrow cUSD</Button>
        </DepositType>
        <DepositType>  
          <Button color="secondary" variant="contained" onClick={processInCEUR}>Borrow cEUR</Button>
        </DepositType>
        <DepositType>  
          <Button color="secondary" variant="contained" onClick={processInCelo}>Borrow CELO</Button>
        </DepositType>  
        <Text>Health Factor: {accountData ? accountData.healthFactor : 'loading...'}</Text>
      </Container>
    </>
  )
}

export default requiresAuth(withView(BorrowScreen))
