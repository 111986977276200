import React, { useEffect, useContext, useState } from 'react'
import AppContext from '../AppContext'
import { getContract } from '../../root'
import BN from 'bignumber.js'
import { print, ether } from '../../utils'

// Hook
const useAvailableBorrow = (txType = 'CELO') => {
  const isCelo = txType === 'CELO'

  const context = useContext(AppContext)
  const [isLoading, setLoading] = useState(true)
  const [availableBorrow, setAvailableBorrow] = useState(0)

  const loadData = async () => {
    const { lendingPool, reserves, priceOracle, tokens } = await getContract()
    const { availableLiquidity } = await lendingPool.methods.getReserveData(reserves[txType]).call()
    const { availableBorrowsETH } = await lendingPool.methods.getUserAccountData(context.user.address).call()

    const token = tokens[txType]

    const celoPerToken = await priceOracle.methods.getAssetPrice(token.address).call()
    
    const availableBorrowConverted = isCelo
      ? availableBorrowsETH
      : BN(availableBorrowsETH).multipliedBy(ether).dividedBy(celoPerToken)

    const maxBorrowValue = BN.minimum(availableBorrowConverted, availableLiquidity).dividedBy(ether)

    setAvailableBorrow(maxBorrowValue)
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  return [{ availableBorrow, isLoading }]
}

export default useAvailableBorrow