import React from 'react'
import { YellowBox } from 'react-native'
import { StatusBar } from 'expo-status-bar'
import {
  HashRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { ContractKitProvider, Alfajores, Mainnet } from '@celo-tools/use-contractkit'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

import AboutValoraScreen from './screens/AboutValora'
import DepositScreen from './screens/Deposit'
import DepositFormScreen from './screens/DepositForm'
import BorrowScreen from './screens/Borrow'
import BorrowFormScreen from './screens/BorrowForm'
import RepayScreen from './screens/Repay'
import RepayFormScreen from './screens/RepayForm'
import RedeemScreen from './screens/Redeem'
import RedeemFormScreen from './screens/RedeemForm'
import TransactionConfrimedScreen from './screens/TransactionConfirmed'
import WelcomeScreen from './screens/Welcome'
import HomeScreen from './screens/Home'

import AppToolbar from './components/AppToolbar'
import AppMenu from './components/AppMenu'
import AppContext from './components/AppContext'
import LoadingOverlay from './components/LoadingOverlay'

import { kit } from './root'
import config from './config'

import { styles as ContractKitStyles, renderProvider, renderActionModal } from './sdk/useContractKit'

YellowBox.ignoreWarnings(['Warning: The provided value \'moz', 'Warning: The provided value \'ms-stream'])

// Utils
const Alert = (props) => (
  <MuiAlert elevation={6} variant="filled" {...props} />
)

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#35D07F'
    },
    secondary: {
      main: '#FBCC5C'
    },
  },
})

// Component
export default class App extends React.Component {
  state = {
    showMenu: false,
    showError: false,
    showLoading: false,
    errorMessage: null,
    user: JSON.parse(localStorage.getItem('valora_user')) 
  }

  constructor(props) {
    super(props)
    if (this.state.user) {
      kit.defaultAccount = this.state.user.address
    }
  }

  closeMenu = () => {
    this.setState({ showMenu: false });
  }

  openMenu = () => {
    this.setState({
      showMenu: true
    })
  }

  showErrorAlert = (errorMessage) => {
    const defaultError = 'Something went wrong. Please try again.'
    this.setState({ showError: true, errorMessage: errorMessage || defaultError })
  }
  
  closeErrorAlert = () => {
    this.setState({ showError: false, errorMessage: null })
  }

  showLoading = () => {
    this.setState({ showLoading: true })
  }

  hideLoading = () => {
    this.setState({ showLoading: false })
  }

  setUser = (user) => {
    this.setState({ user })
    localStorage.setItem('valora_user', JSON.stringify(user))
  }
  
  render() {
    return (
      <>
        <ContractKitStyles />
        <div style={{ height: '100%' }}>
          <ContractKitProvider
            dappName="Moola"
            networks={[config.isMainNet ? MainNet : Alfajores]}
            connectModal={{ renderProvider }}
            actionModal={{ render: renderActionModal }}
          >
            <ThemeProvider theme={theme}>
              <AppContext.Provider 
                value={{ 
                  showError: this.showErrorAlert, 
                  showLoading: this.showLoading,
                  hideLoading: this.hideLoading,
                  user: this.state.user,
                  setUser: this.setUser
                }}
              >
                <Router>
                  <LoadingOverlay opened={this.state.showLoading} transparent />
                  <AppToolbar onClickMenu={this.openMenu} />          
                  <Snackbar 
                    open={this.state.showError} 
                    autoHideDuration={5000} 
                    onClose={this.closeErrorAlert}
                  >
                    <Alert onClose={this.closeErrorAlert} severity="error">
                      {this.state.errorMessage}
                    </Alert>
                  </Snackbar>
                  <Switch>
                    <Route path={['/deposit/:txCurrency/:txValue', '/deposit/:txCurrency']}>
                      <DepositFormScreen />
                    </Route>
                    <Route path="/deposit">
                      <DepositScreen />
                    </Route>
                    <Route path={['/borrow/:txCurrency/:txValue', '/borrow/:txCurrency']}>
                      <BorrowFormScreen />
                    </Route>
                    <Route path="/borrow">
                      <BorrowScreen />
                    </Route>
                    <Route path={['/repay/:txCurrency/:txValue', '/repay/:txCurrency']}>
                      <RepayFormScreen />
                    </Route>
                    <Route path="/repay">
                      <RepayScreen />
                    </Route>
                    <Route path={['/redeem/:txCurrency/:txValue', '/redeem/:txCurrency']}>
                      <RedeemFormScreen />
                    </Route>
                    <Route path="/redeem">
                      <RedeemScreen />
                    </Route>
                    <Route path="/about-valora">
                      <AboutValoraScreen />
                    </Route>
                    <Route path="/transaction-confirmed/:txType/:txCurrency/:txValue/:txHash">
                      <TransactionConfrimedScreen />
                    </Route>
                    <Route path="/welcome">
                      <WelcomeScreen />
                    </Route>
                    <Route path="/">
                      <HomeScreen />
                    </Route>
                  </Switch>
                  <AppMenu
                    opened={this.state.showMenu}
                    closeMenu={this.closeMenu}
                  />
                  <StatusBar style="auto" />
                </Router>
              </AppContext.Provider>
            </ThemeProvider>
          </ContractKitProvider>
        </div>
      </>
    )
  }
}
