import BN from 'bignumber.js'

export const ether = '1000000000000000000';
const ray = '1000000000000000000000000000';
export const maxUint256 = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';


export function print(num) {
  return BN(num).dividedBy(ether).toFixed();
}

export function printRay(num) {
  return BN(num).dividedBy(ray).toFixed();
}

const currenciesMap = {
  'cUSD': '$',
  'cEUR': '€'
}

export function getValuePlaceholder(type) {
  const currencySymbol = currenciesMap[type] || ''

  return type === 'CELO' ? 'XX.xxxx' : `${currencySymbol}XX.xx`
}

export function formatTokenValue(value, type) {
  const amount = BN(value)
  const formattedAmount = amount.lt('0.01') ? amount.toFixed() : amount.toFixed(2)
  
  const currencySymbol = currenciesMap[type] || ''

  return currencySymbol + formattedAmount
}

export function formatCUSD(value) {
  return formatTokenValue(value, 'cUSD')
}

export function formatCEUR(value) {
  return formatTokenValue(value, 'cEUR')
}

export function formatCELO(value) {
  const amount = BN(value)
  return amount.lt('0.0001') ? amount.toFixed() : amount.toFixed(4)
}

export function formatAmount(value, type) {
  return type === 'CELO' ? formatCELO(value) : formatTokenValue(value, type)
}
