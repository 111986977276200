import React, { useEffect, useState } from 'react'
import BN from 'bignumber.js'
import { kit } from '../../root'

const useBalance = (type = 'CELO', initValue) => {
  const [isLoading, setLoading] = useState(true)
  const [balance, setBalance] = useState(initValue || 'Loading...')

  const loadBalance = async () => {
    const token = type === 'CELO' 
      ? await kit.contracts.getGoldToken() 
      : await kit.contracts.getStableToken(type)

    const tokenBalanceBig = await token.balanceOf(kit.defaultAccount)
    
    const tokenBalance = tokenBalanceBig.dividedBy(BN(10).pow(18)).toString()

    setBalance(tokenBalance)
    setLoading(false)
  }

  useEffect(() => {
    loadBalance()
  }, [type])

  return [{ balance, isLoading }]
}

export default useBalance