import React, { useEffect, useContext, useState } from 'react'
import BN from 'bignumber.js'
import AppContext from '../AppContext'
import { print, printRay } from '../../utils'
import { getContract } from '../../root'


// Hook
const useReserveData = (type = 'CELO') => {
  const context = useContext(AppContext)
  const [isLoading, setLoading] = useState(true)
  const [reserveData, setReserveData] = useState(null)

  const loadData = async () => {
    const { lendingPool, reserves } = await getContract()
    const data = await lendingPool.methods.getReserveData(reserves[type]).call()
    const parsedData = {
      totalLiquidity: print(data.totalLiquidity),
      availableLiquidity: print(data.availableLiquidity),
      totalBorrowsStable: print(data.totalBorrowsStable),
      totalBorrowsVariable: print(data.totalBorrowsVariable),
      liquidityRate: printRay(data.liquidityRate),
      variableRate: BN(printRay(data.variableBorrowRate)).multipliedBy(100).toFixed(2, 2),
      stableRate: printRay(data.stableBorrowRate),
      averageStableRate: printRay(data.averageStableBorrowRate),
      utilizationRate: printRay(data.utilizationRate),
      liquidityIndex: printRay(data.liquidityIndex),
      variableBorrowIndex: printRay(data.variableBorrowIndex),
      mToken: data.aTokenAddress,
      lastUpdate: (new Date(BN(data.lastUpdateTimestamp).multipliedBy(1000).toNumber())).toLocaleString(),
    };
    setReserveData(parsedData)
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  return [{ reserveData, isLoading }]
}

export default useReserveData
