import React from 'react'
import { SupportedProviders } from '@celo-tools/use-contractkit'

const handleCEW = (onClick) => () => {
  if (!window.celo) {
    const extensionUrl = 'https://chrome.google.com/webstore/detail/celoextensionwallet/kkilomkmpmkbdnfelcpgckmpcaemjcdh?hl=en'
    window.open(extensionUrl, '_blank')
  } else {
    return onClick()
  }
}

const renderProvider = (provider) => {
  if (provider.name === SupportedProviders.Valora) {
    return null
  }

  const handleConnect = provider.name === SupportedProviders.CeloExtensionWallet 
    ? handleCEW(provider.onClick)
    : provider.onClick

  return (
    <div
      className="tw-flex tw-cursor-pointer tw-py-5 tw-px-4 hover:tw-bg-gray-100 dark:hover:tw-bg-gray-700 tw-transition tw-rounded-md"
      onClick={handleConnect}
    >
      <div className="tw-flex tw-w-1/4">
        <span className="tw-my-auto">
          {typeof provider.image === 'string' ? (
            <img
              src={provider.image}
              alt={`${provider.name} logo`}
              style={{ height: '48px', width: '48px' }}
            />
          ) : (
            provider.image
          )}
        </span>
      </div>
      <div className="tw-w-3/4">
        <div className="tw-text-lg tw-pb-1 tw-font-medium dark:tw-text-gray-300">
          {provider.name}
        </div>
        <div className="tw-text-sm tw-text-gray-600 dark:tw-text-gray-400">
          {provider.description}
        </div>
      </div>
    </div>
  )
}

export default renderProvider
